import Footer from "./Footer";
import Header from "./Header";
import { Outlet,useParams } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import Loading from "../../Component/Loading";
import config from "../../config";
import NavBar from "./NavBar";
const Layout = () => {
  const { isLoading, Message } = useSelector((state) => state.Message);
  const {email}=useParams()
  console.log(email)
  if(email&&email===localStorage.getItem(config.VerifyEmail))
  {localStorage.removeItem(config.VerifyEmail)}

  return (
    <div
      className="p-0 m-0 position-relative"
      style={{
        zIndex: 10,
      }}
    >
      <NavBar/>
      {localStorage.getItem(config.VerifyEmail)&&<div
        className=" alert  alert-danger  text-center fw-bold"
        role="alert" style={{ position:'absolute',top:"20%"}}
      >
        Please Verify your email address <strong className=" text-decoration-underline" style={{color:"#235823"}}>{localStorage.getItem(config.VerifyEmail)}</strong> before Login
      </div>}

      <div className="container p-0">
        {isLoading ? <Loading Message={Message} /> : <Outlet />}
      </div>
      <Footer />
    </div>
  );
};

export { Layout };
