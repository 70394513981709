import React from "react";
import { useDispatch ,useSelector} from "react-redux";
import { nextQuestion,prevQuestion} from '../../Redux/Slice/QuestionHandle'
const Questions = (props) => {
  const dispatch = useDispatch();
  const McqData=JSON.parse(localStorage.getItem('McqData'))
  const {CurrentQuestion}= useSelector((state) => state.QuestionHandle);
  return (

      <div className="d-flex justify-content-center row p-0 mt-3">
        <div className="col-md-12 col-lg-12">
          <div className="border p-2">
            <div className=" p-3 border-bottom">
              <div className="d-flex flex-row align-items-center">
                <h3 className="text-danger">Q.{CurrentQuestion}:</h3>
                <h5 className="mt-1 ml-2">{McqData.McqOptions[CurrentQuestion-1].MCQText}</h5>
              </div>
              <div className="d-flex flex-column ">
                {McqData.McqOptions[CurrentQuestion-1].MCQOptions.map((opt, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className="col-1">
                        <input
                          type="radio"
                          name={"Q" + CurrentQuestion}
                          value={i}
                          onChange={props.ChangHandler}
                          checked={
                            parseInt(props.AnsList[CurrentQuestion]) ===
                            parseInt(i)
                          }
                        />
                      </div>
                      <div className="col-11">{opt.Option}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center p-3 bg-white">
              <button
                className="btn btn-primary d-flex align-items-center btn-danger"
                type="button"
                onClick={()=>dispatch(prevQuestion())}
                disabled={CurrentQuestion===1}
              >
                <i className="fa fa-arrow-left  mr-1"></i>&nbsp;Previous
              </button>
              <button
                className="btn btn-primary border-success align-items-center btn-success"
                type="button"
                onClick={()=>dispatch(nextQuestion())}
                disabled={McqData.McqOptions.length===CurrentQuestion}
              >
                Next&nbsp;<i className="fa fa-arrow-right ml-2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
 
  );
};

export default Questions;
