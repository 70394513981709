import React, { Fragment, useState, useEffect } from "react";
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useSelector } from "react-redux";
const QuizResult = () => {
  const { McqOptions } = useSelector((state) => state.SelectTopic);
  const { AnsList } = useSelector((state) => state.AnswerHandle);
  const [ShowQuestion, setShowQuestion] = useState(false);
  const [Score, setScore] = useState(0);

  // Get Final Result
  useEffect(() => {
    setScore(getResult(McqOptions, AnsList));
  }, [McqOptions, AnsList]);
  const getResult = (McqOptions, AnsList) => {
    let rScore = 0;
    McqOptions.forEach((Questions, q) => {
      //console.log(Questions, q+1)
      Questions.MCQOptions.forEach((opt, i) => {
        if (opt.Ans) {
          //  console.log(q,i)
          //  console.log('AnsList',parseInt(AnsList[q+1]))
          if (parseInt(AnsList[q + 1]) === i) {
            // console.log(Score)
            rScore = rScore + 1;
          }
        }
      });
    });
    return rScore;
  };

  return (
    <Fragment>
      <div className="border border-light p-3 mb-4">
        <div className="text-center">
          {
            <div >
              <div style={{ width: "30%" ,marginLeft:"auto",marginRight:"auto"} } >
                <span className="strong fw-bold ">{Score}/{McqOptions.length}</span>
                <CircularProgressbar
                value={Math.round(Score*100.0/McqOptions.length)}
                text={`${Math.round(Score*100.0/McqOptions.length)}%`}
             
                styles={buildStyles({
                  textColor: "#000000",
                  pathColor:  Math.round(Score*100.0/McqOptions.length)<60?"red":"green",
                  trailColor: "transparent",
            
          
                })}
              />
              </div>
            </div>
          }
          {!ShowQuestion && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowQuestion(true)}
            >
              Show Question With Answer
            </button>
          )}
        </div>
      </div>

      {ShowQuestion && (
     
          <div className="Container h6">
            {McqOptions.map((q, i) => (
              <div key={i}>
                <div className="row py-3">
                  {" "}
                  QNo.{q.MCQNo} : {q.MCQText}
                </div>
                <div className="row">
                  {q.MCQOptions.map((opt, i) => (
                    <div className="form-check col-lg-3 col-md-6" key={i}>
                      <input
                        type="radio"
                        name={q.MCQNo}
                        value={i}
                        defaultChecked={
                          parseInt(AnsList[q.MCQNo]) === parseInt(i)
                        }
                        disabled={true}
                      />
                      <label
                        className="form-check-label ps-2"
                        htmlFor={"mcq".concat(q.MCQNo)}
                        style={{
                          color: opt.Ans
                            ? parseInt(AnsList[q.MCQNo]) === parseInt(i)
                              ? "Green"
                              : "red"
                            : "inherit",
                        }}
                        
                      >
                        {opt.Option}
                      </label>
                    </div>
                  ))}
                </div>
                <hr />
              </div>
            ))}
          </div>
      
      )}
    </Fragment>
  );
};

export { QuizResult };
