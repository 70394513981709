import React, { Fragment } from "react";
import SelectTopic from "../../dashboard/components/SelectTopic";
import { useSelector } from "react-redux";

const QuizInfo = () => {
  const { McqDetail,McqOptions } = useSelector((state) => state.SelectTopic);
  return (
    <Fragment>
      <SelectTopic />
      {Object.keys(McqDetail).length > 0 && (
        <div className="container p-5 strong fw-bold">
          <div className="row">
            <div className="col-3">Grade</div>
            <div className="col-9">{McqDetail.Grade}</div>
          </div>
          <div className="row">
            <div className="col-3">Course</div>
            <div className="col-9">{McqDetail.Course}</div>
          </div>
          <div className="row">
            <div className="col-3">Chapter</div>
            <div className="col-9">{McqDetail.Chapter}</div>
          </div>
          <div className="row">
            <div className="col-3">Topic</div>
            <div className="col-9">{McqDetail.Topic}</div>
          </div>
          <div className="row">
          <div className="col-3">No of Questions</div>
          <div className="col-9">{McqOptions.length}</div>
        </div>
        </div>
      )}
    </Fragment>
  );
};

export { QuizInfo };
