import { createSlice } from "@reduxjs/toolkit";
export const CounterDown = createSlice({
  name: "CounterDown",
  initialState: {
    isStart: false,
    isEnd:false,
    seconds: 0,
    minutes: 15
  },
  reducers: {
    setCounterDown: (state, action) => {
      state.seconds = action.payload.seconds;
      state.minutes= action.payload.minutes;
      state.isEnd =false;
      state.isStart =false;
    },
    reState: (state, action) => {
      state.seconds =0;
      state.minutes= 15;
      state.isEnd =false;
      state.isStart =false;
    },
    Start:(state, action)=>{state.isStart =true;state.isEnd =false;},
    update: (state, action) => {
     if(state.isStart&&!state.isEnd){
        if (state.seconds > 0) {
          state.seconds -=1;
        }
        if (state.seconds === 0) {
          if (state.minutes === 0) {
            state.isEnd =true;
          } else {
            state.minutes-= 1;
            state.seconds = 59;
          }
        }
      }
     
    },
  },
});
export const { setCounterDown, update ,Start,reState} = CounterDown.actions;
export default CounterDown.reducer;
