import React from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { setStep, nextStep, prevStep } from "../../Redux/Slice/Step";
import { useDispatch, useSelector } from "react-redux";
import { Start,reState } from "../../Redux/Slice/CounterDown";
import { Fragment } from "react";
import { setQuestion } from './../../Redux/Slice/QuestionHandle';
import { useNavigate } from "react-router-dom";
const Footer = () => {
  //use Redux set Step
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { Step } = useSelector((state) => state.Step);
  const {isEnd} = useSelector((state) => state.CounterDown);
  const { McqOptions } = useSelector((state) => state.SelectTopic);
  const {CurrentQuestion} = useSelector((state) => state.QuestionHandle);

 const Next = ({ Step }) => {
    switch (Step) {
      case 0:
        return <Fragment> Next</Fragment>;
      case 1:
        return <Fragment> Start Quiz</Fragment>;
      case 2:
        return <Fragment> Show Result</Fragment>;
        case 3:
          return <Fragment> Finish</Fragment>;
      default:
        break;
    }
  };
  const Prev = ({ Step }) => {
    switch (Step) {
      case 0:
        return <Fragment> Previous</Fragment>;
      case 1:
        return <Fragment> Again Choice Topic</Fragment>;
      case 2:
        return <Fragment> Previous</Fragment>;
        case 3:
          return <Fragment> Try Again</Fragment>;
      default:
        break;
    }
  };
  return (
    <div>
    {
      Step===2&&<div className="row px-2"><div className="col-2 text-center pt-2  strong fw-bold">Question</div><div  className="col-8"><Slider
      min={1}
      max={JSON.parse(localStorage.getItem('McqData')).McqOptions.length}
      value={CurrentQuestion}
   
      onChange={(value) => {
      dispatch(setQuestion({'CurrentQuestion':value}))
      }}
      /></div>
      <div className="col-2 text-center pt-2  strong fw-bold"><span>{CurrentQuestion}</span>/
      <span>{JSON.parse(localStorage.getItem("McqData")).McqOptions.length}</span></div>
      </div>
    }
      <div className="d-flex flex-row justify-content-between align-items-center p-3 ">
       {Step===1?<button
          className="btn btn-primary d-flex align-items-center btn-danger"
          type="button"
         
          onClick={() => dispatch(prevStep())}
        >
         <i className="fa fa-arrow-left  me-1"></i>
          
          {<Prev Step={Step} />}
        </button>:<div className="d-flex align-items-center"></div>}
        
        
        <button
          className="btn btn-primary border-success align-items-center btn-success"
          type="button"
          onClick={() => {
            if(Step===3)
            {// Finish Quiz
              dispatch(setStep(0));
              dispatch(reState(0));
              localStorage.removeItem('McqData');
              navigate(`/quiz`);
            }else{
            dispatch(nextStep());
          }
            if (!isEnd&&Step===1) {
              dispatch(Start());
            }
          }}
          disabled={McqOptions.length<=0}
        >
          {<Next Step={Step} />}
          {Step!==3&& <i className="fa fa-arrow-right ms-2"></i>}
        </button>
      </div>

 
    </div>
  );
};

export { Footer };
