import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { QuizInfo, QuizStart,QuizMcq, QuizResult } from "./Pages/index";
import { update } from "../Redux/Slice/CounterDown";
import { useSelector, useDispatch } from "react-redux";
import { setStep } from "../Redux/Slice/Step";
function Quiz() {
  const { Step } = useSelector((state) => state.Step);
  const dispatch = useDispatch();
  const [QuizData, setQuizData] = useState([]);
  const QuizTitles = ["Quiz Select Topic", "Quiz Instruction", false, "Result"];
  const { isEnd, seconds} = useSelector((state) => state.CounterDown);
  useEffect(() => {
    let timeout = setInterval(() => {
      dispatch(update());
    }, 1000);
    if (isEnd) {
      dispatch(setStep(3));}
    return () => clearInterval(timeout);
  }, [seconds]);
  const PageDisplay = () => {
    switch (Step) {
      case 0:
        return <QuizInfo QuizData={QuizData} setQuizData={setQuizData} />;
      case 1:
        return <QuizStart QuizData={QuizData} setQuizData={setQuizData} />;
      case 2:
        return <QuizMcq />;
      case 3:
        return <QuizResult />;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <Layout Header={QuizTitles[Step]}>
        <div className="body">{PageDisplay()}</div>
      </Layout>
    </div>
  );
}

export { Quiz };
