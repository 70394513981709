import React  from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
const index = (props) => {
  document.title = "Quiz";
  return (
      <div className="container mt-2 p-0">
      <Header Header={props.Header} />
      {props.children}
      <Footer Header={props.Header} />
      </div>

  );
};

export default index;
