import React from 'react'

export default function SectionAboutUs() {
  return (
    <section id="aboutus" className="p-0 m-0 pt-3  vh-md-100">
    <div className="container pt-5">
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="text-black">About Us</h1>
        <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sapiente laudantium, unde ad vitae iusto quaerat dolor a porro nesciunt praesentium cumque dignissimos consequuntur perspiciatis accusantium tempora autem, expedita, quo quidem.
        </p>
      </div>
    </div>
    <div className="row">
    <div className="col-md-4" >
    
    <img  className='d-md-block d-none 'src="./assets/img/aboutus.jpg" alt="About Us" width="100%" height="100%" />
  
    </div>
    <div className="col-md-8  text-black" >
  
    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li className="nav-item" role="presentation">
      <button
        className="nav-link active"
        id="pills-OurVision-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-OurVision"
        type="button"
        role="tab"
        aria-controls="pills-OurVision"
        aria-selected="true"
      >
      Our Vision
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        className="nav-link"
        id="pills-OurAmis-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-OurAmis"
        type="button"
        role="tab"
        aria-controls="pills-OurAmis"
        aria-selected="false"
      >
      Our Aims
      </button>
    </li>
  
  </ul>
  <div className="tab-content" id="pills-tabContent">
    <div
      className="tab-pane fade show active"
      id="pills-OurVision"
      role="tabpanel"
      aria-labelledby="pills-OurVision-tab"
    >
    <p className="font-italic text-muted text-start">
    Our vision is to help you by providing internationally recognised
    professional qualifications, aligned with business and soft skills
    training, to educate future leaders; those you need to succeed in
    the global marketplace.
  </p>
    </div>
    <div
      className="tab-pane fade"
      id="pills-OurAmis"
      role="tabpanel"
      aria-labelledby="pills-OurAmis-tab"
    >
    <ul className=" d-flex flex-column align-items-start font-italic text-muted text-start">
    <li style={{ listStyleType: "disc" }}>
      Promoting academic excellence
    </li>
    <li style={{ listStyleType: "disc" }}>
      Enhancing analytical and study skills
    </li>
    <li style={{ listStyleType: "disc" }}>
      Building leadership skills
    </li>
    <li style={{ listStyleType: "disc" }}>
      Fostering an ethical outlook and sense of civic responsibility
    </li>
    <li style={{ listStyleType: "disc" }}>
      developing an understanding of global issues
    </li>
    <li style={{ listStyleType: "disc" }}>Encouraging creativity.</li>
  </ul>
    </div>
  </div>
    
    </div>
    </div>
    </div>
    
    </section>
  )
}
