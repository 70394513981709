import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function SectionOurTeams() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1200, min: 768 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 768, min: 576 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1
    }
  };
  const OurTeams = [
    {
      FullName: "Muhammad Majid",
      ImgUrl: "majid.jpg",
      Post: "CEO",
      Email: "majid.qauiub@idealtsa.com",
      CellNo: "0306-7728788",
      SocialLink: [
        {
          Icon: "fa-facebook",
          Color: "#4267B2",
          Link: "https://facebook.com/idealtsacom",
        },
        {
          Icon: "fa-twitter",
          Color: "#00acee",
          Link: "https://twitter.com/idealtsa",
        },
        {
          Icon: "fa-youtube",
          Color: "#c4302b",
          Link: "https://youtube.com/c/idealtsa",
        },
      ],
    },
    {
      FullName: "Rana Aman Ullah",
      ImgUrl: "aaman.jpg",
      Post: "Director",
      Email: "rana.aman.ullah@idealtsa.com",
      CellNo: "0301-7016594",
      SocialLink: [
        {
          Icon: "fa-facebook",
          Color: "#4267B2",
          Link: "https://facebook.com/rana.amankhan.7",
        },
      ],
    },
    {
      FullName: "Ijaz Sumra",
      ImgUrl: "ijaz.jpg",
      Post: "Manager",
      Email: "ijaz.sumra@idealtsa.com",
      CellNo: "0300-9681647",
      SocialLink: [
        {
          Icon: "fa-facebook",
          Color: "#4267B2",
          Link: "https://facebook.com/ijaz.sumra.35",
        },
      ],
    },
    {
      FullName: "Rashid Mehmood",
      ImgUrl: "rashid.jpg",
      Post: "Assistant Director",
      Email: "rashid.mehmood@idealtsa.com",
      CellNo: "0308-6087570",
      SocialLink: [
        {
          Icon: "fa-facebook",
          Color: "#4267B2",
          Link: "https://facebook.com/rashidsumra.rashidsumra.37",
        },
      ],
    },
  ];
  return (
    <section id="ourTeams" className="p-0 m-0 pt-3">
    
      <div className="text-center pt-5">
 
        <div className="row p-0 m-0">
          <div className="col-12 text-center p-0 m-0">
            <h1 className="text-black pt-1">Our Experts Teams</h1>
          
          </div>
        </div>
        <div className="row  py-0 m-0  px-2 ">
       <Carousel
       swipeable={false}
       draggable={false}
       showDots={false}
         infinite={true} responsive={responsive} autoPlay={true} >
            {OurTeams.map((card, i) => {
              return (
                  <div className="card site-card mx-1" key={i}>
                    <div className="card-header">
                      <img
                        className=""
                        src={"./assets/img/" + card.ImgUrl}
                        alt={"Card Image :" + card.FullName}
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{card.FullName}</h5>

                      <div className="d-flex flex-column  align-items-start ms-5 mt-3">
                        <a href={"mailto:" + card.Email} className="mail">
                          <i className="fa-solid fa-envelope"></i> :{" "}
                          {card.Email}
                        </a>
                        <p className="cellno m-0">
                          <i className="fa-solid fa-phone"></i> : {card.CellNo}
                        </p>
                        <p className="job  m-0">
                          <i className="fa-solid fa-building"></i> &nbsp;:{" "}
                          {card.Post}
                        </p>
                      </div>
                      <button className="btn  my-3 ">More...</button>
                    </div>
                    <div className="card-footer border border-1 bg-light  p-0">
                      <ul className="social-links list-inline list-social-icons m-0 p-0">
                        {card.SocialLink.map((socialItems, k) => {
                          return (
                            <li className="list-inline-item" key={k}>
                              <a href={socialItems.Link} target="_Blank" rel="noreferrer">
                                <span className="fa-stack fa-lg">
                                  <i
                                    className={
                                      "fa-brands " + socialItems.Icon + " fa-1x"
                                    }
                                    style={{ color: socialItems.Color }}
                                  />
                                </span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
              );
            })}
            </Carousel>
        </div>
      </div>
    </section>
  );
}
