import { createSlice } from '@reduxjs/toolkit'
export const Token = createSlice({
  name: 'Token',
  initialState: {
    isToken:false,
    Token:''
  },
  reducers: {
    setToken: (state, action) => {
      state.isToken= true;
      state.Token= action.payload.Token;
    },
    reSetToken: (state, action) => {
        state.isToken= false;
        state.Token= '';
      },

  },
})

export const { setToken,reSetToken} = Token.actions
export default Token.reducer
