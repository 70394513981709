import { createSlice } from '@reduxjs/toolkit'
export const Step = createSlice({
  name: 'Step',
  initialState: {
    Step:0
  },
  reducers: {
    nextStep: (state, action) => {
      state.Step = ++state.Step;
    },
    prevStep: (state, action) => {
        state.Step = --state.Step;
      },
      setStep: (state, action) => {
        state.Step = action.payload;
      },

  },
})
export const { setStep,nextStep,prevStep} = Step.actions
export default Step.reducer
