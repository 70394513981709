import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function SectionCarousel() {
  return (
    <div className='h-100  p-0 m-0'>
    <Carousel showThumbs={false} infiniteLoop showStatus={false} width={'100%'}  autoPlay>
    <div>
      <img  className='h-100 p-0 m-0'src={"./assets/img/carousel/4.jpg"} aria-hidden alt="" width="100%" height="100%" />
      <p className="legend text-white">
        It is the supreme art of the teacher to awaken joy in creative
        expression and knowledge
      </p>
    </div>
    <div>
      <img className='h-100  p-0 m-0' src={"./assets/img/carousel/6.jpg"} aria-hidden alt="" width="100%" height="100%" />
      <p className="legend">
        You have to grow from the inside out. None can teach you, none can
        make you spiritual. There is no other teacher but your own soul.
      </p>
    </div>
    <div>
      <img  className='h-100  p-0 m-0'src={"./assets/img/carousel/3.jpg"} aria-hidden alt="" width="100%" height="100%" />
      <p className="legend">
        The task of the modern educator is not to cut down jungles, but to
        irrigate deserts.
      </p>
    </div>
  </Carousel>
  </div>
  )
}
