import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { Home, ResetPassword, Error } from "./Pages/index";
import { Layout } from "./Pages/Layout/index";

import { Quiz } from "./Quiz/index";
import { Paper } from "./Paper/index";
export default function App() {
  const {isLogin} = useSelector((state) => state.User);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<Home />} />
          <Route path=":email" element={<Home />} />
        </Route>
        <Route path="/"  element={<Layout/>}>
        <Route
        path="/quiz"
        element={isLogin ? <Quiz /> : <Navigate to="/" />}
      />
      <Route
        path="/paper"
        element={isLogin ? <Paper /> : <Navigate to="/" />}
      /></Route>
        <Route path="/ResetPassword/:id" element={<ResetPassword />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  )
}
