import React from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import SectionAboutUs from "./Layout/SectionAboutUs";
import SectionOurTeams from "./Layout/SectionOurTeams";
import SectionContactUs from "./Layout/SectionContactUs";
import SectionCarousel from "./Layout/SectionCarousel";
import TopArrow from "./Layout/TopArrow";
import Loading from "../Component/Loading";

function Home() {
  return (
    <div className=" container-fluid p-0 m-0 text-center text-white">
    <Loading />
      <Header />
      <main>
        <SectionOurTeams />
        <SectionCarousel />
        <SectionAboutUs />
        <SectionContactUs />
      </main>
      <Footer />
    <TopArrow/>
    </div>
  );
}

export { Home };
