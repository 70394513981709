import { createSlice } from '@reduxjs/toolkit'
export const SelectTopic = createSlice({
  name: 'SelectTopic',
  initialState: {
    isloading:false,
    GradeSelected: '',
    CourseSelected: '',
    ChapterSelected:'',
    TopicSelected:'',
    McqOptions:[],
    McqDetail:{},
    isSelect:false
  },
  reducers: {
    setSelectTopic: (state, action) => {
      state.GradeSelected = action.payload.GradeSelected;
      state.CourseSelected= action.payload.CourseSelected;
      state.ChapterSelected = action.payload.ChapterSelected;
      state.TopicSelected = action.payload.TopicSelected;
      state.McqOptions = action.payload.McqOptions;
      state.McqDetail = action.payload.McqDetail ;
      state.isSelect = action.payload.isSelect;

    },
    setLoading:(state, action) => {state.isloading=action.payload},
    clearData:(state, action) => {
      state.isloading=false;
      state.GradeSelected= '';
      state.CourseSelected= '';
      state.ChapterSelected='';
      state.TopicSelected='';
      state.McqOptions=[];
      state.McqDetail={};
      state.isSelect=false
    },
    setGradeSelected:(state, action)=>{state.GradeSelected=action.payload;},

  },
})

export const { setSelectTopic,setLoading,clearData,setGradeSelected } = SelectTopic.actions
export default SelectTopic.reducer
