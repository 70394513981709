import React, { useState, useEffect } from "react";
import axios from "axios";
import { setSelectTopic } from "../../Redux/Slice/SelectTopic";
import { useDispatch } from "react-redux";
const SelectTopic = () => {
  //use Redux set SelectTopic
  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);
  //String whole data
  const [MCQD, setMCQD] = useState({
   'Grade':'',
   'Course':'',
   'Chapter':'',
   'Topic':'',
  });

  //For Grade
  const [GradeSelected, setGradeSelected] = useState("");
  const [GradeOptions, setGradeOptions] = useState([]);
  //For Course
  const [CourseSelected, setCourseSelected] = useState("");
  const [CourseOptions, setCourseOptions] = useState([]);
  //For Chapter
  const [ChapterSelected, setChapterSelected] = useState("");
  const [ChapterOptions, setChapterOptions] = useState([]);
  //For Topic
  const [TopicSelected, setTopicSelected] = useState("");
  const [TopicOptions, setTopicOptions] = useState([]);
  //For Mcq
  const [McqOptions, setMcqOptions] = useState([]);
  //Get Grade List
  useEffect(() => {
    setLoading(true);
    axios
      .get(`g/`)
      .then((res) => {
        if (res.statusText === "OK") {
          setGradeOptions(res.data);
          dispatch(
            setSelectTopic({
              GradeSelected: "",
              CourseSelected: "",
              ChapterSelected: "",
              TopicSelected: "",
              McqOptions: [],
              McqDetail:{},
              isSelect: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setGradeOptions([]);
      });
    setLoading(false);
  }, []);

  // Get Course list
  useEffect(() => {
    setLoading(true);
    if (GradeSelected)
      axios
        .get(`gc/${GradeSelected}`)
        .then((res) => {
          //console.log(res.data.SubjectId)
          if (res.statusText === "OK") {
            dispatch(
              setSelectTopic({
                GradeSelected: "",
                CourseSelected: "",
                ChapterSelected: "",
                TopicSelected: "",
                McqOptions: [],
                McqDetail:{},
                isSelect: false,
              })
            );
            setCourseOptions(
              res.data.map((d) => ({
                _id: d._id,
                CourseNo: d.CourseId.CourseNo,
                CourseName: d.CourseId.CourseName,
              }))
            );
          }
        })
        .catch((err) => {
          setCourseOptions([]);
          console.log(err);
        });
    setLoading(false);
  }, [GradeSelected]);

  // Get Chapter list
  useEffect(() => {
    setLoading(true);
    if (CourseSelected)
      axios
        .get(`ch/${CourseSelected}`)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch(
              setSelectTopic({
                GradeSelected: "",
                CourseSelected: "",
                ChapterSelected: "",
                TopicSelected: "",
                McqOptions: [],
                McqDetail:{},
                isSelect: false,
              })
            );
            setChapterOptions(
              res.data.map((d) => ({
                _id: d._id,
                ChapterNo: d.ChapterNo,
                ChapterName: d.ChapterName,
              }))
            );
          }
        })
        .catch((err) => {
          setChapterOptions([]);
          console.log(err);
        });
    setLoading(false);
  }, [CourseSelected]);
  // Get Topic list
  useEffect(() => {
    setLoading(true);
    if (ChapterSelected)
      axios
        .get(`t/${ChapterSelected}`)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch(
              setSelectTopic({
                GradeSelected: "",
                CourseSelected: "",
                ChapterSelected: "",
                TopicSelected: "",
                McqOptions: [],
                McqDetail:{},
                isSelect: false,
              })
            );
            setTopicOptions(
              res.data.map((d) => ({
                _id: d._id,
                TopicNo: d.TopicNo,
                TopicName: d.TopicName,
              }))
            );
          }
        })
        .catch((err) => {
          setTopicOptions([]);
          console.log(err);
        });
    setLoading(false);
  }, [ChapterSelected]);
  //console.log(TopicSelected)
  useEffect(() => {
    setLoading(true);
    if (TopicSelected)
      axios
        .get(`mcq/${TopicSelected}`)
        .then((res) => {
          if (res.statusText === "OK") {
            const dataToSave = res.data.map((d) => ({
              _id: d._id,
              MCQNo: d.MCQNo,
              MCQText: d.MCQText,
              MCQOptions: d.MCQOptions,
            }));
            setMcqOptions(dataToSave);
            dispatch(
              setSelectTopic({
                GradeSelected,
                CourseSelected,
                ChapterSelected,
                TopicSelected,
                McqOptions: dataToSave,
                McqDetail:MCQD,
                isSelect: true,

              })   
            );
            localStorage.setItem("McqData", JSON.stringify({
              GradeSelected,
              CourseSelected,
              ChapterSelected,
              TopicSelected,
              McqOptions: dataToSave,
              McqDetail:MCQD,
              isSelect: true,

            }));
          }
        })
        .catch((err) => {
          setMcqOptions([]);
          console.log(err);
        });
    setLoading(false);
  }, [TopicSelected]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 py-2">
          {!Loading && GradeOptions.length !== 0 && (
            <select
              className="form-select"
              value={GradeSelected}
              onChange={(e) => {
                setGradeSelected(e.target.value);
             
  setMCQD({
    'Grade':e.target[e.target.selectedIndex].getAttribute('data-grade'),
    'Course':MCQD.Course,
    'Chapter':MCQD.Chapter,
    'Topic':MCQD.Topic
   })
                setCourseSelected("");
                setCourseOptions([]);
                setChapterSelected("");
                setChapterOptions([]);
                setTopicSelected("");
                setTopicOptions([]);
              }}
            >
              <option>--Select-Grade--</option>
              {GradeOptions.map((GradeD) => (
                <option key={GradeD._id} value={GradeD._id} data-grade={GradeD.GradeName}>
                  {GradeD.GradeName}
                </option>
              ))}
            </select>
          )}
        </div>
        {!Loading && CourseOptions.length !== 0 && (
          <div className="col-lg-3 col-md-6 py-2">
            <select
              className="form-select"
              value={CourseSelected}
              onChange={(e) => {
                setCourseSelected(e.target.value);
                setMCQD({
                  'Grade':MCQD.Grade,
                  'Course':e.target[e.target.selectedIndex].getAttribute('data-course'),
                  'Chapter':MCQD.Chapter,
                  'Topic':MCQD.Topic
    
                 })
                setChapterSelected("");
                setChapterOptions([]);
                setTopicSelected("");
                setTopicOptions([]);
              }}
            >
              <option>--Select-Subject--</option>
              {CourseOptions.map((CourseD) => (
                <option key={CourseD._id} value={CourseD._id} data-course={CourseD.CourseName}>
                  {CourseD.CourseName}
                </option>
              ))}
            </select>
          </div>
        )}
        {!Loading && ChapterOptions.length !== 0 && (
          <div className="col-lg-3 col-md-6 py-2">
            <select
              className="form-select"
              value={ChapterSelected}
              onChange={(e) => {
                setChapterSelected(e.target.value);
                setMCQD({
                  'Grade':MCQD.Grade,
                  'Course':MCQD.Course,
                  'Chapter':e.target[e.target.selectedIndex].getAttribute('data-chapter'),
                  'Topic':MCQD.Topic
                 })
                setTopicSelected("");
                setTopicOptions([]);
              }}
            >
              <option>--Select-Chapter--</option>
              {ChapterOptions.map((ChapterD) => (
                <option key={ChapterD._id} value={ChapterD._id} data-chapter={ChapterD.ChapterName}>
                  {ChapterD.ChapterName}
                </option>
              ))}
            </select>
          </div>
        )}
        {!Loading && TopicOptions.length !== 0 && (
          <div className="col-lg-3 col-md-6 py-2">
            <select
              className="form-select"
              value={TopicSelected}
              onChange={(e) => {
                setTopicSelected(e.target.value);
                setMCQD({
                  'Grade':MCQD.Grade,
                  'Course':MCQD.Course,
                  'Chapter':MCQD.Chapter,
                  'Topic':e.target[e.target.selectedIndex].getAttribute('data-topic')
                 })
              }}
            >
              <option>--Select-Topic--</option>
              {TopicOptions.map((TopicD) => (
                <option key={TopicD._id} value={TopicD._id} data-topic={TopicD.TopicName}>
                  {TopicD.TopicName}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {
      //   <div className="row">
      //     <div className="text-center pt-3">
      //       <button
      //         type="button"
      //         className="btn btn-primary"
      //         onClick={() => {
      //           console.log(
      //             "ooooooooo",
      //             JSON.parse(localStorage.getItem("McqOptions")).length
      //           );
      //         }}
      //       >
      //         NEXT
      //         <i className="fas fa-arrow-right ps-2"></i>
      //       </button>
      //     </div>
      //   </div>
      }
      {/*<div className="row py-2">
    <div className="Container h6">
    {!Loading &&
      McqOptions &&
      McqOptions.map((q, i) => (
        <div key={i}>
          <div className="row py-3">
            {" "}
            QNo.{q.MCQNo} : {q.MCQText}
          </div>
          <div className="row">
            {q.MCQOptions.map((opt, i) => (
              <div className="form-check col-lg-3 col-md-6" key={i}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={"mcq".concat(q.MCQNo)}
                  id={"mcq".concat(q.MCQNo).concat(i)}
                />
                <label
                  className="form-check-label"
                  htmlFor={"mcq".concat(q.MCQNo)}
                >
                  {opt.Option}
                </label>
              </div>
            ))}
          </div>
          <hr />
        </div>
      ))}
  </div>
            </div>*/}
    </div>
  );
};

export default SelectTopic;
