import { createSlice } from '@reduxjs/toolkit'
export const AnswerHandle = createSlice({
  name: 'AnswerHandle',
  initialState: {
    AnsList:[],
  },
  reducers: {
    setAnswer: (state, action) => {
      state.AnsList = action.payload.AnsList;
    },

  },
})
export const { setAnswer} = AnswerHandle.actions
export default AnswerHandle.reducer
