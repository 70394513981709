import * as Yup from "yup";
import React, {Fragment } from "react";
import axios from "axios";
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Field, Formik, ErrorMessage } from "formik";
import { FormGroup } from "reactstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import { LoginUser} from "../Redux/Slice/User";
import Loading from "../Component/Loading";
import {setLoading,resetLoading,setError, clearError} from "../Redux/Slice/Message";
import config from "../config";
function ResetPassword() {
  document.title = "Reset Password";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const { ErrorMsg} = useSelector((state) => state.Message);
  const handleSubmitResetPassword = (values) => {
    dispatch(setLoading({ Message: "Reset Password" }));
    let user = [];
    user = { password: values.password };
    axios
      .post("/user/resetpassword/" + id, user)
      .then((res) => {
        toast.success("Password update Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(resetLoading());
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((err) => {
        if (err.response.data.message) {
          dispatch(setError({ ErrorMsg: err.response.data.message }));
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        dispatch(resetLoading());
        setTimeout(() => {
          navigate("/");
        }, 3000);
      });
  };
  return (
    <Fragment>
    <Loading/>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className=" vh-100 d-flex  flex-column  justify-content-center  "
        style={{ backgroundColor: "#47544d" }}
      >
        <div className="card text-center mx-auto  my-auto shadow-sm p-5 bg-white">
          <div className="card-header h3 bg-white display-6 text-black">
            <strong>Reset Your Password</strong>
          </div>
          <div className="card-body">
            <div className="card-text pt-3">
              <p className="text-start">Please enter new password below.</p>
              <Formik
                initialValues={{ password: "", passwordConfirmation: "" }}
                onSubmit={handleSubmitResetPassword}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .required("Password is required")
                    .min(6, "Password must be at least 6 characters")
                    .oneOf(
                      [Yup.ref("passwordConfirmation"), null],
                      "Passwords must match"
                    ),
                  passwordConfirmation: Yup.string()
                    .required("Confirm password is required")
                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
                })}
              >
                {() => (
                  <Form className="form-container">
                    <span
                      style={{ color: "red", padding: "0px", margin: "0px" }}
                    >
                      {ErrorMsg}
                    </span>
                    <FormGroup>
                      <ErrorMessage
                        style={{ color: "red", paddingBottom: "5px" }}
                        name="password"
                        component="div"
                      />
                      <Field
                        type="password"
                        name="password"
                        className={"form-control"}
                        placeholder="New Password"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field
                        type="password"
                        name="passwordConfirmation"
                        className={"form-control"}
                        placeholder="Re-enter new Password"
                      />
                    </FormGroup>
                    <button
                      type="submit"
                      className="btn  text-white mt-3 py-2 "
                      style={{ backgroundColor: "#47544d" }}
                    >
                      Save New Password
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="d-flex  justify-content-center my-3 ">
          <Link
            className="btn  text-white py-2 "
            to="/"
            style={{
              backgroundColor: "#47544d",
              border: "1px solid white",
              boxSizing: "border-box",
            }}
          >
            <i className="fa-solid fa-house-chimney pe-2"></i> Back to Home
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export { ResetPassword };
