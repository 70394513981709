import NavBar from './NavBar';
import React from 'react';
import config from '../../config';
import { useParams } from 'react-router-dom';


export default function Header() {
  const {email}=useParams()
  console.log(email)
  if(email&&email===localStorage.getItem(config.VerifyEmail))
  {localStorage.removeItem(config.VerifyEmail)}

  return (
    <header  id="header" className="vh-100  p-0 m-0 bg-dark " style={{ 
      backgroundImage: `url('./assets/img/cover.jpg')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      padding: '0px',
      margin: '0px'
    }}>
    <NavBar/>
    {localStorage.getItem(config.VerifyEmail)&&<div
      className=" alert  alert-danger  text-center fw-bold w-75 mx-auto"
      role="alert" style={{ top:"20%"}}
      
    >
      Please verify your email address <strong className=" text-decoration-underline" style={{color:"#235823"}}>{localStorage.getItem(config.VerifyEmail)}</strong> before Login.
    </div>}
       <div className="d-flex flex-column justify-content-center align-content-center h-100 w-75 mx-auto">
          <h1 className="mb-5">Welcome to <strong className="" style={{color:"rgb(93 243 35)"}}>Ideal Training Science Academy</strong></h1>
           <h4>The best way to predict the future is to create it</h4>
         <p className="lead">Change is the end result of all true learning</p>
         <p className="lead">
           <a
             href="#"
             className="btn btn-md btn-secondary fw-bold border-white bg-white text-black p-2"
           >
             Get Started
           </a>
         </p>
       </div>
     </header>
  )
}