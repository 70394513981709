import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, LogoutUser } from "../Redux/Slice/User";
import {
  setLoading,
  resetLoading,
  setError,
  clearError,
} from "../Redux/Slice/Message";
import config from "../config";
export const TokenVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.User);
  useEffect(() => {
    dispatch(
      setLoading({ Message: "Checking Authentication of Current User" })
    );
    if (localStorage.getItem(config.Token)) {
      axios
        .post("/user/verfytoken", {
          token: localStorage.getItem(config.Token),
        })
        .then((res) => {
          if (res.statusText === "OK") {
            axios
              .get("/user/getuserbyid/" + res.data.user_id)
              .then((res) => {
                dispatch(
                  LoginUser({
                    email: res.data.email,
                    _id: res.data._id,
                    name: res.data.name,
                    cellno: res.data.cellno,
                    token: res.data.token,
                    isLogin: true,
                  })
                );
                dispatch(clearError());
              })
              .catch((err) => {
                console.log(err);
                dispatch(setError({ ErrorMsg: err }));
                dispatch(LogoutUser());
                localStorage.removeItem(config.Token);
                navigate("/");
              });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setError({ ErrorMsg: err }));
          dispatch(LogoutUser());
          localStorage.removeItem(config.Token);
          navigate("/");
        });
    }

    dispatch(resetLoading());
  }, [token]);
  return <Fragment></Fragment>;
};
