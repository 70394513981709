import { createSlice } from '@reduxjs/toolkit'
export const QuestionHandle = createSlice({
  name: 'QuestionHandle',
  initialState: {
    CurrentQuestion:1,
  },
  reducers: {
    nextQuestion: (state, action) => {
      state.CurrentQuestion = ++state.CurrentQuestion;
    },
    prevQuestion: (state, action) => {
        state.CurrentQuestion = --state.CurrentQuestion;
      },
      setQuestion: (state, action) => {
        state.CurrentQuestion = action.payload.CurrentQuestion;
      },


  },
})
export const {setQuestion, nextQuestion,prevQuestion} = QuestionHandle.actions
export default QuestionHandle.reducer
