import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import User from './Slice/User';
import { UsersApi } from './api/UsersApi';
import auth  from './Slice/auth';
import SelectTopic from './Slice/SelectTopic';
import Step  from './Slice/Step';
import CounterDown  from './Slice/CounterDown';
import  QuestionHandle  from './Slice/QuestionHandle';
import AnswerHandle  from './Slice/AnswerHandle';
import Token  from './Slice/Token';
import Message  from './Slice/Message';
const Store = configureStore({
    reducer: {
        User,
        auth,
        SelectTopic,
        Step,
        CounterDown,
        QuestionHandle,
        AnswerHandle,
        Token,
        Message,
       [UsersApi.reducerPath]:UsersApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>getDefaultMiddleware().concat(UsersApi.middleware),



    devTools: true,
})


setupListeners(Store.dispatch);
export default Store;