import React, { Fragment } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { useSelector } from "react-redux";
const Header = (props) => {
 // const { CurrentQuestion } = useSelector((state) => state.QuestionHandle);
  const { minutes, seconds } = useSelector((state) => state.CounterDown);
  const {AnsList } = useSelector((state) => state.AnswerHandle);
  const McqData = JSON.parse(localStorage.getItem("McqData"));
  let percentage = Math.round( ((15 * 60 - (minutes * 60 + seconds)) / (15 * 60)) * 100 );
  return (
    <Fragment>
      {props.Header ? (
        <div className="row    fw-bold  d-flex align-items-center py-3">
          {props.Header}
        </div>
      ) : (
        <div className="row fw-bold  d-flex align-items-center">
          <div className="col-md-4 py-2">
            <div className="btn-group align-items-center  ">
              <button
                type="button"
                className="btn bg-success text-light dropdown-toggle mx-auto justify-content-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                MCQ TOPIC
              </button>
              <ul className="dropdown-menu">
                <li className="dropdown-item">
                  <span className="strong fw-bold">Grade:</span>
                  {McqData.McqDetail.Grade}
                </li>
                <li className="dropdown-item">
                  <span className="strong fw-bold">Subject:</span>
                  {McqData.McqDetail.Course}
                </li>
                <li className="dropdown-item">
                  <span className="strong fw-bold">Chapter:</span>
                  {McqData.McqDetail.Chapter}
                </li>
                <li className="dropdown-item">
                  <span className="strong fw-bold">Topic:</span>{" "}
                  {McqData.McqDetail.Topic}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 text-center py-2">
            <span>
              Answer{" "}
              <span>
                <ProgressBar
                  completed={Object.keys(AnsList).length}
                  bgColor="green"
                  baseBgColor="#ff0000"
                  labelColor="#000000"
                  margin="0"
                  padding="0"
                  animateOnRender
                  maxCompleted={McqData.McqOptions.length}
                  // Given Answer to Question
                  customLabel={Math.round((Object.keys(AnsList).length * 100.0) / McqData.McqOptions.length)+"%"}
                />
              </span>
           { 
            //<span>{CurrentQuestion}</span> /
            //<span>{McqData.McqOptions.length}</span>
          }
            </span>
        
          </div>
          <div className="col-md-4 d-flex  justify-content-center py-2 ps-3 ">
            <div style={{ width: "20%", padding: 0, margin: 0 }}>
              <CircularProgressbar
                value={percentage}
                text={
                  <Fragment>
                    {minutes < 9 ? "0" + minutes : minutes}:
                    {seconds < 9 ? "0" + seconds : seconds}
                  </Fragment>
                }
                styles={buildStyles({
                  textColor: "inherit",
                  pathColor: percentage < 75 ? "green" : "red",
                  backgroundColor: "transparent",
                })}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export { Header };
