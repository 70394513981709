import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Col, Row, FormGroup } from "reactstrap";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Reaptcha from "reaptcha";
import axios from "axios";
import config  from '../../config';

export default function SectionContactUs() {
  const [verified, setverified] = useState(false);
  const [status, setStatus] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("invalid email")
      .required("Email is required"),
    tel: Yup.string().required("Cell no is required"),
    fullName: Yup.string().required("Full name is required"),
    subject: Yup.string()
      .min(3, "Subject minimum length 3")
      .required("Subject is required"),
    message: Yup.string()
      .min(10, "Message minimum length 10")
      .required("Message is required"),
  });

  const onVerify = (recaptchaResponse) => {
    setverified(true);
  };
  const onExpire = () => {
    setverified(false);
    setStatus(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setverified(false);
      setStatus(false);
    }, 3000);
  }, [status]);

  const handleSubmit = (e) => {
    if (verified) {
      setStatus(true);
      toast.info("Messag is Sending", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      axios
        .post("/mailer", e)
        .then((res) => {
          if (res.statusText === "OK") {
            toast.success("Successfully Send Message", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setStatus(false);
          } else {
            console.log(res);
            toast.error("Something went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error("Something went Wrong", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      toast.error("Are you are human being?", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <section id="contactus" className="p-0 m-0 pt-3 vh-md-100 ">
    <div className=" text-center pt-5">
    <h1 className="text-black pt-1">Contact Us</h1>
  </div>
  <div className="container p-0 py-5">
  <ToastContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />

  <Row className="m-0 ">
    {!status ? (
      <Col md={6} sm={12}>
        <Formik
          initialValues={{
            fullName: "",
            email: "",
            tel: "",
            subject: "",
            message: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(values) => (
            <Form className="form-container">
              <FormGroup>
                <Field
                  type="text"
                  name="fullName"
                  className={"form-control"}
                  placeholder="Full Name"
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="fullName"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  type="text"
                  name="email"
                  className={"form-control"}
                  placeholder="Email"
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="email"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  type="tel"
                  name="tel"
                  className={"form-control"}
                  placeholder="Cell No."
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="tel"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  type="text"
                  name="subject"
                  className={"form-control"}
                  placeholder="Subject"
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="subject"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  type="text"
                  as="textarea"
                  name="message"
                  className={"form-control"}
                  placeholder="Message"
                  rows="3"
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="message"
                />
              </FormGroup>

              <FormGroup className="d-flex justify-content-end ">
                <Reaptcha
                  sitekey={config.SITE_KEY}
                  onVerify={onVerify}
                  onExpire={onExpire}
                />
              </FormGroup>

              <FormGroup className="d-flex justify-content-end">
                <button
                  className={"btn rounded p-2 px-5 fw-bold btn-primary "}
                  type="submit"
                  disabled={status}
                >
                  Send
                </button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </Col>
    ) : (
      <Col md={6} sm={12}>
        Sending message........
      </Col>
    )}

    <Col md={6} sm={12}>
   
    <p >
      <iframe
        title="Ideal Training Academy MAp"
        className="col-lg-12 col-md-12 col-sm-12 p-0 w-100"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3469.7282953570743!2d72.23897511458223!3d29.582507847201864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393c66abe032da15%3A0x8a19a558b256d0fc!2sStreet%20To%20People%20Bazaar%2C%20Khairpur%20Tamewali%2C%20Bahawalpur%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1648625609460!5m2!1sen!2s"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </p>

    
 
   
   <div className="ps-3 font-italic text-muted  d-flex flex-row text-start">
   <i className="fa-solid fa-location-dot fa-1x"></i>
   <p className="ps-1"><strong className="text-uppercase">
   IDEAL TRAINING SCIENCE ACADEMY,
 </strong> 
   SUPERIOR SCHOOL OF EXCELLENCE CHOWK SAUDIA, KHAIRPUR TAMEWALI (63060) , BAHAWALPUR, PUNJAB, PAKISTAN
   </p></div>
   <div className="ps-3 font-italic text-muted  d-flex flex-row text-start">
   <i className="fa-solid fa-envelope fa-1x"></i>
   <p className="ps-1">  <strong>  <a  className=" ps-2 " href={`mailto:admin@idealtsa.com?subject=""&body=""`}>
   admin@idealtsa.com
 </a></strong></p></div>
   <div className="ps-3 font-italic text-muted  d-flex flex-row text-start">
   <i className="fa-solid fa-phone fa-1x"></i>
   <p className="ps-1"><strong>
   +92-306-7728788
 </strong> 
 </p></div>


    </Col>
  </Row>
</div>
  
    </section>
  )
}
