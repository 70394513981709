import React, { useState } from "react";
import config from "../config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useDispatch } from "react-redux";
import { clearError } from "../Redux/Slice/Message";
import { useSelector } from "react-redux";
import { LogoutUser } from "../Redux/Slice/User";
import { setResetPasswordToggle, setSignInUpToggle } from "../Redux/Slice/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import Loading from "./Loading";
import Login from "./Login";
import Signup from "./Signup";

export default function AuthUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Modal open state
  const [modal, setModal] = useState(false);

  const islogin = useSelector((state) => state.User.isLogin);
  const { ResetPasswordToggle, SignInUpToggle } = useSelector(
    (state) => state.auth
  );
  const activeUser = useSelector((state) => state.User.name);
  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const logout = () => {
    localStorage.removeItem(config.Token);
    dispatch(LogoutUser());
    navigate("/");
  };

  useEffect(() => {
    dispatch(clearError());
  }, []);

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {
        <ul
          className="navbar-nav mx-auto my-3 my-md-0  text-center  "
          style={{
            boxSizing:'border-box'
          }}
        >
          {!islogin ? (
            <Fragment>
              <li
                className="nav-item p-2"
                onClick={() => {
                  toggle();
                  dispatch(setSignInUpToggle(false));
                }}
              >
                Create Account?
              </li>
              <li
                className="nav-item btn btn-outline-light" 
                onClick={() => {
                  toggle();
                  dispatch(setSignInUpToggle(true));
                  dispatch(setResetPasswordToggle(false));
                  dispatch(clearError());
                }}
              >
                Login
              </li>
            </Fragment>
          ) : (
            <Fragment>
              <li className="nav-item  p-md-2 ">{activeUser}</li>
              <li className="btn btn-outline-light nav-item" onClick={logout}>
                {" "}
                Logout
              </li>
            </Fragment>
          )}
        </ul>
      }

      <Modal isOpen={modal} toggle={toggle}  style={{
        boxSizing:'border-box'
      }}>
        <Loading />
        <ModalHeader toggle={toggle}>
          {SignInUpToggle
            ? !ResetPasswordToggle
              ? "Login"
              : "Reset Password"
            : "Sign Up"}
        </ModalHeader>
        <ModalBody>
          {SignInUpToggle ? (
            <Login toggle={toggle} />
          ) : (
            <Signup toggle={toggle} />
          )}
        </ModalBody>
        {!ResetPasswordToggle&&SignInUpToggle && 
          (<ModalFooter>
             
              <Button
                color="primary"
                onClick={() => {
                  dispatch(setSignInUpToggle(false));
                }}
                outline
              >
                Sign Up
              </Button>
          </ModalFooter>)}
      </Modal>
    </Fragment>
  );
}
