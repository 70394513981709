import React, { Fragment} from "react";
import * as Yup from "yup";
import { useDispatch,useSelector } from "react-redux";
import { Form, Field, Formik, ErrorMessage } from "formik";
import { LoginUser} from "../Redux/Slice/User";
import {setLoading,resetLoading,setError, clearError} from "../Redux/Slice/Message";
import config from "../config";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {FormGroup} from "reactstrap";
import LostPassword from './LostPassword';
import {setResetPasswordToggle} from "../Redux/Slice/auth";
export default function Login(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ErrorMsg} = useSelector((state) => state.Message);
    const {ResetPasswordToggle} = useSelector((state) => state.auth);
    const initialvalues = {
        email: "",
        password: ""
      };
    const validationSchema = Yup.object().shape({
          email: Yup.string()
            .email("invalid email")
            .required("email is required"),
          password: Yup.string().required("password is required"),
        });

  const handleSubmitLogin = (values) => {
    dispatch(setLoading({ Message: "Login User" }));
   let userLoginData = { email: values.email, password: values.password };
   axios
     .post("/user/signin", userLoginData)
     .then((res) => {
        props.toggle();
       dispatch(
         LoginUser({
           email: res.data.email,
           _id: res.data._id,
           name: res.data.name,
           cellno: res.data.cellno,
           token: res.data.token,
           isLogin: true,
         })
       );
       localStorage.setItem(config.Token, res.data.token);
       toast.success("Login Successfully", {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
       });
       localStorage.removeItem(config.VerifyEmail);
       dispatch(resetLoading());
       navigate("/");
     })
     .catch((err) => {
       if (err.response.data.message) {
         dispatch(setError({ ErrorMsg: err.response.data.message }));
         toast.error(err.response.data.message, {
           position: "top-right",
           autoClose: 3000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
       }
       dispatch(resetLoading());
       navigate("/");
     });
 };
  return ResetPasswordToggle?<LostPassword toggle={props.toggle}/>: 
  <Fragment>
  <Formik
    initialValues={initialvalues}
    onSubmit={handleSubmitLogin}
    validationSchema={validationSchema}
  >
    {() => (
      <Form className="form-container">
        <span style={{ color: "red", padding: "0px", margin: "0px" }}>
          {ErrorMsg}
        </span>
        <FormGroup>
          <Field
            type="text"
            name="email"
            onFocus={() => dispatch(clearError())}
            className={"form-control"}
            placeholder="Email"
          />
          <ErrorMessage
            style={{ color: "red" }}
            component="div"
            name="email"
          />
        </FormGroup>
        <FormGroup>
          <Field
            type="password"
            name="password"
            className={"form-control"}
            placeholder="Password"
          />
          <ErrorMessage
            style={{ color: "red" }}
            component="div"
            name="password"
          />{" "}
        </FormGroup>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
        <span
          className=" text-primary text-decoration-underline px-2"
          onClick={() => {
            dispatch(setResetPasswordToggle(!ResetPasswordToggle));
          }}
        >
          Lost your password?
        </span>
      </Form>
    )}
  </Formik>
</Fragment>
}
