import React, { Component } from "react";
export default class Footer extends Component {
  render() {
    return (
      <footer
        className="p-0 m-0 text-center d-print-none text-black"
        style={{ backgroundColor: "#f1f1f1", paddingBottom: "10px" }}
      >
        <div>
          {" "}
          <h4 className="  pt-3"> IDEAL TRAINING SCIENCE ACADEMY </h4>
          <p className="px-5 ">
            The Academy started its journey in 2021. The management of The
            Academy, in keeping with their commitment to imparting quality
            education, wanted to bring this excellence to the master levels.
          </p>
          <ul className="list-inline  p-0 m-0">
            <li className="list-inline-item">
              <a
                href="https://facebook.com/idealtsacom"
                style={{ color: "blue" }}
              >
                <i className="fa-brands fa-facebook  fa-2x   " />{" "}
              </a>{" "}
            </li>
            <li className="list-inline-item">
              <a
                href="https://twitter.com/idealtsa"
                style={{ color: "#00acee" }}
              >
                <i className="fa-brands fa-twitter fa-2x ]" />{" "}
              </a>{" "}
            </li>
            <li className="list-inline-item">
              <a
                href="https://youtube.com/c/idealtsa"
                style={{ color: "#c4302b" }}
              >
                <i className="fa-brands fa-youtube fa-2x " />{" "}
              </a>{" "}
            </li>
            <li className="list-inline-item ">
              <a href="https://github.com/idealtsa" style={{ color: "#000" }}>
                <i className="fa-brands fa-github  fa-2x " />{" "}
              </a>{" "}
            </li>
          </ul>
        </div>
        <div
          className="row p-0 m-0 py-3 mt-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          <div className="col-md-6 text-md-start ">
            <i className="fa-solid fa-copyright"></i>&nbsp;2022 Copyright:
            <strong>&nbsp;IDEAL TRAINING SCIENCE ACADEMY</strong>
          </div>
          <div className="col-md-6 ">
            <ul className="navbar-nav d-flex  flex-row justify-content-md-end  justify-content-center p-0 m-0">
              <li className="nav-item p-0">
                <span className="nav-link  p-0 pe-1 text-black">
                  Terms of Use
                </span>
              </li>
              <li className="nav-item p-0">
                <span className="nav-link  p-0 pe-1 text-black">
                  | Privacy Policy
                </span>
              </li>
              <li className="nav-item p-0">
                <span className="nav-link p-0 text-black">| Cookie Policy</span>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}
