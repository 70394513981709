import React, { Fragment } from "react";
import { NavLink} from "react-router-dom";
import img from "../../images/logo.png";
import { useSelector} from "react-redux";
import AuthUser from "../../Component/AuthUser";
import { TokenVerification } from "../../Component/TokenVerification";

export default function NavBar() {
  const {isLogin} = useSelector((state) => state.User);
  const NavBarList = [
    { To: "/#", href:true, Content: "Home", visitor: true },
    { To: "/#ourTeams",href:true, Content: "Our Teams", visitor: true },
    { To: "/quiz", href:false,Content: "Quiz", visitor: isLogin },
    { To: "/#contactus",href:true, Content: "Contact Us", visitor: true },
    { To: "/#aboutus",href:true, Content: "About Us", visitor: true },
  ];
  return (<Fragment>
    <TokenVerification/>
    <nav className="navbar  fixed-top navbar-expand-lg navbar-dark shadow-sm p-0 m-0" style={{backgroundColor:"#47544d"}}>
    <div className="container-fluid py-2">
    <img
    src={img}
    className=" bg-white rounded-circle"
    height={50}
    alt="Ideal Training Science Academy"
  />
  <div
    className="ps-2 d-md-inline-block text-uppercase d-none"
    height={28}
  >
    Ideal Training Science Academy
  </div>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav me-auto mb-2 mb-md-0">
          {NavBarList.map((list, i) => {
            return (
              list.visitor && (
                <li className="nav-item" key={i}>
                  {list.href? <a className="nav-link " aria-current="page" href={list.To}>
                  {list.Content}
                </a>:<NavLink
                    className="nav-link"
                    aria-current="page"
                    to= {list.To}
                  >
                    {list.Content}
                  </NavLink>}
                </li>
              )
            );
          })}
          
        </ul>
        <form className="d-flex">
        <AuthUser />
        </form>
      </div>
    </div>
  </nav>
    </Fragment>
  );
}
