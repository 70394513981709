import React, { Fragment } from 'react'

export default function TopArrow() {
  return (
    <Fragment>  <a
    href="#"
    className=" bg-transparent  text-decoration-none text"
    style={{
      position: "sticky",
      left: "90%",
      bottom: "20px",
      color: "#47544d",
    }}
  >
    <i className="fa-solid fa-circle-arrow-up fa-2x"></i>
    <br />
    <strong>TOP</strong>
  </a></Fragment>
  )
}
