import React, { Fragment,useState } from "react";
import * as Yup from "yup";
import { useDispatch,useSelector } from "react-redux";
import { Form, Field, Formik, ErrorMessage } from "formik";
import {setLoading,resetLoading,setError} from "../Redux/Slice/Message";
import config from "../config";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Reaptcha from "reaptcha";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {FormGroup,Col,Row} from "reactstrap";
import {setSignInUpToggle} from "../Redux/Slice/auth";

export default function Signup(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ErrorMsg} = useSelector((state) => state.Message);
    const [verified, setverified] = useState(false);
    const initialvalues = {
      name: "",
      cellno: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      };
    const validationSchema = Yup.object().shape({
      cellno: Yup.string().required("cell no is required"),
      name: Yup.string().required("user name is required"),
      email: Yup.string()
        .email("invalid email")
        .required("email is Required"),
      password: Yup.string()
        .required("password is required")
        .min(6, "password must be at least 6 characters")
        .oneOf(
          [Yup.ref("passwordConfirmation"), null],
          "passwords must match"
        ),
      passwordConfirmation: Yup.string()
        .required("confirm password is required")
        .oneOf([Yup.ref("password"), null], "passwords must match"),
    });

    const handleSubmitSignup = (values) => {
      if(verified){
       dispatch(
        setLoading({ Message: "Sign up under process" })
      );
        const userSignupData = {
          name: values.name,
          email: values.email,
          cellno: values.cellno,
          password: values.password,
        };
        axios
          .post("/user/signup", userSignupData)
          .then((res) => {
            console.log(res);
            props.toggle();
            toast.success("Signup Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.setItem(config.VerifyEmail, values.email);
            dispatch(resetLoading());
            navigate("/");
          })
          .catch((err) => {
            dispatch(setSignInUpToggle(true));
            console.log(err)
            if (err.response.data.message) {
              dispatch(setError({ ErrorMsg:err.response.data.message}));
              toast.error(err.response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            dispatch(resetLoading());
            navigate("/");
          });
          
      }else{
        dispatch(setError({ ErrorMsg:'Verify you are not a robot.'}));
        toast.info('Verify you are not a robot.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  
  return <Fragment>
  <Formik
  initialValues={initialvalues}
  onSubmit={handleSubmitSignup}
  validationSchema={validationSchema}
>
  {() => (
    <Form className="form-container">
      <Row>
        {" "}
        <Col style={{ color: "red" ,paddingBottom:'5px' ,textAlign:'center'}}>{ErrorMsg}</Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Field
              type="text"
              name="name"
              className={"form-control"}
              placeholder="Full Name"
            />
            <ErrorMessage
              style={{ color: "red" }}
              name="name"
              component="div"
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Field
          type="text"
          name="email"
          className={"form-control"}
          placeholder="Email"
        />
        <ErrorMessage
          style={{ color: "red" }}
          name="email"
          component="div"
        />
      </FormGroup>
      <FormGroup>
        <Field
          type="text"
          name="cellno"
          className={"form-control"}
          placeholder="Cell Number"
        />
        <ErrorMessage
          style={{ color: "red" }}
          name="cellno"
          component="div"
        />
      </FormGroup>
      <FormGroup>
        <Field
          type="password"
          name="password"
          className={"form-control"}
          placeholder="Password"
        />
      </FormGroup>
      <FormGroup>
        <Field
          type="password"
          name="passwordConfirmation"
          className={"form-control"}
          placeholder="Password Confirmation"
        />
        <ErrorMessage
          style={{ color: "red" }}
          name="password"
          component="div"
        />{" "}
      </FormGroup>
      <FormGroup className="d-flex justify-content-end ">
      <Reaptcha

        sitekey={config.SITE_KEY}
        onVerify={()=>{setverified(true)}}
        onExpire={()=>{setverified(false)}}
      />
    </FormGroup>
      <button type="submit" className="btn btn-primary">
        Sign Up
      </button>
      <span
        className=" text-primary text-decoration-underline px-2"
        onClick={() => dispatch(setSignInUpToggle(true))}
      >
        Already User?
      </span>
    </Form>
  )}
</Formik>
</Fragment>
}
