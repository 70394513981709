import React from "react";
import { Fragment, useState,useEffect } from "react";
import Layout from "./Layout";
import Page1 from "./Pages/Page1";

const Paper = () => {
const NoPage=3;

  const [Page, setPage] = useState(1);
  const PageHanding = ["Home","page2","lastPage"];
  const DisplayPage = () => {
    switch (Page) {
      case 1:
        return <Fragment> <Page1/></Fragment>;
      case 2:
        return <Fragment> Page 2</Fragment>;
      case NoPage:
        return <Fragment> Last Page</Fragment>;
    }
  };

  return (
    <Layout Page={Page} NoPage={NoPage} setPage={setPage} PageHanding={PageHanding}>
      <DisplayPage />
    </Layout>
  );
};

export { Paper };
