import Questions from "./Questions";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAnswer } from "../../Redux/Slice/AnswerHandle";
const QuizMcq = () => {
  //use Redux
  const dispatch = useDispatch();
  const { CurrentQuestion } = useSelector((state) => state.QuestionHandle);
  const [AnsList, setAnsList] = useState([]);
  const ChangHandler = (e) => {
    setAnsList({
      ...AnsList,
      [CurrentQuestion]: e.target.value,
    });
  };
  useEffect(() => {
    dispatch(setAnswer({ AnsList }));
  }, [AnsList]);
  return (
    <Fragment>
      <Questions
        AnsList={AnsList}
        setAnsList={setAnsList}
        ChangHandler={ChangHandler}
      />
    </Fragment>
  );
};

export {QuizMcq};
