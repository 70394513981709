import React, { Fragment,useState } from "react";
import * as Yup from "yup";
import { useDispatch,useSelector } from "react-redux";
import { Form, Field, Formik, ErrorMessage } from "formik";
import {setLoading,resetLoading,setError, clearError} from "../Redux/Slice/Message";
import config from "../config";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {FormGroup} from "reactstrap";
import Reaptcha from "reaptcha";

export default function LostPassword(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [verified, setverified] = useState(false);
    const { ErrorMsg} = useSelector((state) => state.Message);
    const initialvalues ={ email: "" };
    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .email("invalid email")
          .required("email is Required"),
      });
    const handleSubmitLostPassword = (values) => {
        if(verified){
         dispatch(
          setLoading({ Message: "Password reset :Sending email a link" })
        );;
        let user = [];
        user = { email: values.email };
        axios
          .post("/user/forgettingpassword", user)
          .then((res) => {
            props.toggle();
            toast.success("Email Send Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch(resetLoading());;
            navigate("/");
          })
          .catch((err) => {
            if (err.response.data.message) {
              dispatch(setError({ ErrorMsg:err.response.data.message}));
              toast.error(err.response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            dispatch(resetLoading());
            navigate("/");
          });
      
        }else{
          dispatch(setError({ ErrorMsg:'Verify you are not a robot.'}));
          toast.info('Verify you are not a robot.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
  return (
    <Fragment>
    <Formik
      initialValues={initialvalues}
      onSubmit={handleSubmitLostPassword}
      validationSchema={validationSchema}
    >
      {() => (
        <Form className="form-container">
          <span
            style={{ color: "red", padding: "0px", margin: "0px" }}
          >
            {ErrorMsg}
          </span>
          <FormGroup>
          <label className="alert alert-info w-100  h6 text-center">
          Lost your password? Please enter your email address.You will receive a link
          to create a new password via email.
          </label>
            <Field
              type="text"
              name="email"
              onFocus={() => dispatch(clearError())}
              className={"form-control"}
              placeholder="Email"
            />
            <ErrorMessage
              style={{ color: "red" }}
              component="div"
              name="email"
            />
          </FormGroup>
          <FormGroup className="d-flex justify-content-end ">
          <Reaptcha
      
            sitekey={config.SITE_KEY}
            onVerify={()=>{setverified(true)}}
            onExpire={()=>{setverified(false)}}
          />
        </FormGroup>
          <button type="submit" className="btn btn-primary ">
            Reset Password
          </button>
        </Form>
      )}
    </Formik>
  </Fragment>
  )
}
