import React from "react";
import {useSelector } from "react-redux";
import Moment from 'moment';

const QuizStart = () => {
  const McqData=JSON.parse(localStorage.getItem('McqData'))
  const {seconds,minutes} = useSelector((state) => state.CounterDown);
  
  return (
    <div className="container py-3">
      <div className="row text-start fw-bold">
        <div className="col-4">
          <span >Date:</span>
          <span >
            <span
              data-tooltip
              data-html-tooltip-title="Jul 12, 2017 at 11:59pm"
            >
              {Moment().format('DD-MM-YYYY')}
            </span>
          </span>
        </div>
        <div className="col-8">
          <div className="row ">
            <div className="col-6">
              <div >Points:{McqData.McqOptions.length*2}</div>

              <div >Questions :{McqData.McqOptions.length}</div>
            </div>

            <div className="col-6">
              <div>Time Limit :{minutes<9?'0'+minutes:minutes}:{seconds<9?'0'+seconds:seconds} Minutes</div>

              <div>Allowed Attempts:1</div>
            </div>
          </div>
        </div>
      </div>

    
      <div className="pt-2">
        <p>
          This quiz consists of 10 multiple-choice questions. To be successful
          with the weekly quizzes, it’s important to thoroughly read chapter 5
          in the textbook.&nbsp; It willimport 
 also be extremely useful to study the
          key terms at the end of the chapter and review the{" "}
          <em>Test Your Knowledge</em> activity at the end of the chapter. Keep
          the following in mind:
        </p>
        <ul >
          <li >
            <strong>Multiple Attempts</strong> - You will have three attempts
            for this quiz with your highest score being recorded in the grade
            book.
          </li>
          <li >
            <strong>Timing</strong> - You will need to complete each of your
            attempts in one sitting, as you are allotted 30 minutes to complete
            each attempt.
          </li>
          <li >
            <strong>Answers</strong> - You may review your answer-choices and
            compare them to the correct answers after your final attempt.
          </li>
        </ul>
        <p>
          To start, click the <strong>"Take the Quiz"</strong> button. When
          finished, click the <strong>"Submit Quiz"</strong> button.
        </p>
      </div>
    </div>
  );
};

export { QuizStart };
